<template>
  <div>
    <b-row>
      <!--Tabs-->
      <b-col cols="12" class="mb-3">
        <b-tabs align="center">
          <b-tab
            v-for="(item, index) in status"
            :key="index"
            :title="item.label"
            @click="fetchOrders(item.value)"
          />
        </b-tabs>
      </b-col>
    </b-row>

    <MyTable
      :rows="rows"
      :columns="columns"
      :is-loading="isLoading"
      :checkbox-enabled="checkbox"
      :has-export="true"
      :date-range-enabled="true"
      :date-range-field="'delivery_date'"
      :export-type="[
        { value: 'xlsx', text: 'Excel' },
        { value: 'csv', text: 'CSV' },
      ]"
      @transferMiniDC="transferMiniDC"
      @bulkEditDeliveryDate="bulkEditDeliveryDate"
      @handleExport="handleReportExport"
    >
      <template v-slot="{ props }">
        <span v-if="props.column.field === 'reference_id'">
          <b-button variant="primary" class="w-100" :to="`/order-details/${props.row.id}`">
            {{ props.row.reference_id }}
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'po_expiry_date'">
          {{ $helpers.formatDate(props.row.po_expiry_date) }}
        </span>

        <span v-else-if="props.column.field === 'date_added'">
          {{ $helpers.formatDate(props.row.date_added) }}
        </span>

        <span v-else-if="props.column.field === 'delivery_date'">
          {{ $helpers.formatDateTime(props.row.delivery_date) }}
        </span>

        <span v-else-if="props.column.field === 'sender_address'">
          {{ props.row.sender && props.row.sender.address1 ? props.row.sender.complete : '-' }}
        </span>

        <span v-else-if="props.column.field === 'recipient_address'">
          {{
            props.row.recipient && props.row.recipient.address1 ? props.row.recipient.complete : '-'
          }}
        </span>

        <span v-else-if="props.column.field === 'status'">
          <span v-if="props.row.status.value === 8">
            <b-badge
              :variant="props.row.status.color_name"
              disabled
              class="w-100 p-1 font-weight-bolder"
              >{{ props.row.status.text }}</b-badge
            >
            <span v-if="props.row.received_at" class="d-block mt-1 text-success">
              <span class="d-block">Order Received</span>
              <span>({{ $helpers.formatDateTime(props.row.received_at) }})</span>
            </span>
          </span>
          <span v-else-if="props.row.status.value === 11 || props.row.status.value === 15">
            <b-badge
              :variant="props.row.refund.status.color_name"
              disabled
              class="d-block w-100 p-1 font-weight-bolder"
            >
              {{ props.row.refund.status.text }}
            </b-badge>
          </span>
          <span v-else>
            <b-badge :variant="props.row.status.color_name" class="w-100 p-1 font-weight-bolder">
              {{ props.row.status.text }}
            </b-badge>
          </span>
        </span>
        <span v-else-if="props.column.field === 'actions'">
          <div v-if="currentStatus === 'active'">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleAction('assign-smarttruck', props.row)">
                Assign Smarttruck
              </b-dropdown-item>
              <b-dropdown-item @click="handleAction('edit-order', props.row)">
                Edit Order
              </b-dropdown-item>
              <b-dropdown-item @click="handleAction('request-refund', props.row)">
                Request Refund
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            v-else-if="currentStatus === 'scheduled' || currentStatus === 'cancellation_request'"
          >
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleAction('edit-order', props.row)">
                Edit Order
              </b-dropdown-item>
              <b-dropdown-item @click="handleAction('update-status', props.row)">
                Update Status
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-else-if="currentStatus === 'history' || currentStatus === 'manually_assigned'">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item disabled> No actions available </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            v-else-if="currentStatus === 'assign-smarttruck' || currentStatus === 'minidc_orders'"
          >
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleAction('edit-order', props.row)">
                Edit Order
              </b-dropdown-item>
              <b-dropdown-item @click="handleAction('update-status', props.row)">
                Update Status
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div v-else-if="currentStatus === 'request_refund'">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleAction('refund-details', props.row)">
                Refund Details
              </b-dropdown-item>
              <b-dropdown-item @click="handleAction('in-process', props.row, 1)">
                In Process
              </b-dropdown-item>
              <b-dropdown-item @click="handleAction('approved', props.row, 2)">
                Approve
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.refund.type.value === 1"
                @click="handleAction('paid', props.row, 4)"
              >
                Paid
              </b-dropdown-item>
              <b-dropdown-item @click="handleAction('decline-request', props.row, 3)">
                Decline Request
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div v-else-if="currentStatus === 'accept_declined'">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleAction('accept_declined', props.row)">
                View Payment Details
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.value === 14"
                @click="handleAction('mark_as_paid', props.row)"
              >
                Set as Active
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>

    <!--Back to Dashboard Button-->
    <b-row>
      <b-col cols="12 text-center">
        <b-button variant="danger" :to="{ name: 'dashboard' }" class="mr-1">
          Back to Dashboard
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      ref="modal"
      :size="modalSize"
      :title="modalTitle"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <AssignSmarttruck
        v-if="modalType === 'assign-smarttruck'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
      <EditOrder
        v-else-if="modalType === 'edit-order'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
      <RequestRefund
        v-else-if="modalType === 'request-refund'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
      <RefundDetails
        v-else-if="modalType === 'refund-details'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
      <UpdateStatus
        v-else-if="modalType === 'update-status'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
      <PaymentDetails
        v-else-if="modalType === 'accept_declined'"
        :order="selectedRow"
        @success="handleModalClosure"
      />

      <PaidOrder
        v-else-if="modalType === 'mark_as_paid'"
        :order="selectedRow"
        @success="handleModalClosure"
      />
    </b-modal>
    <b-modal v-model="modalVisible" title="Transfer to MiniDC Outlet" centered>
      <b-form-group class="my-2">
        <v-select
          v-model="selectedBranch"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="branches"
          :clearable="true"
          placeholder="Choose Branch"
          label="area"
        />
      </b-form-group>
      <template #modal-footer="{ cancel }">
        <b-button variant="warning" @click="cancel('cancel')"> Cancel </b-button>
        <b-button variant="primary" @click="callUpdate()"> Save </b-button>
      </template>
    </b-modal>
    <b-modal v-model="modalBulkDate" title="Bulk Update Delivery Date" centered>
      <b-form-group class="my-2">
        <flat-pickr
          id="start_date"
          v-model="selectedDate"
          class="form-control"
          name="selected_date"
          :config="{ minDate: new Date().toISOString().substr(0, 10) }"
        />
      </b-form-group>
      <template #modal-footer="{ cancel }">
        <b-button variant="warning" @click="cancel('cancel')"> Cancel </b-button>
        <b-button variant="primary" @click="callUpdateDate()"> Save </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  MyTable,
  AssignSmarttruck,
  EditOrder,
  RequestRefund,
  UpdateStatus,
  RefundDetails,
  PaymentDetails,
} from '@/components'
import axios from '@axios'
import vSelect from 'vue-select'
import PaidOrder from '@/components/OrderTracker/PaidOrder.vue'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'MyOrderTracker',
  components: {
    MyTable,
    flatPickr,
    AssignSmarttruck,
    EditOrder,
    RequestRefund,
    UpdateStatus,
    RefundDetails,
    PaymentDetails,
    PaidOrder,
    vSelect,
  },
  data() {
    const columns = []

    const status = [
      { label: 'All', value: '' },
      { label: 'Active', value: 'active' },
      { label: 'Scheduled', value: 'scheduled' },
      { label: 'History', value: 'history' },
      { label: 'Manually Assigned', value: 'manually_assigned' },
      { label: 'MiniDC Orders', value: 'minidc_orders' },
      { label: 'Request Refund', value: 'request_refund' },
      { label: 'Accepted and Declined', value: 'accept_declined' },
    ]

    return {
      columns,
      status,
      rows: [],
      branches: [],
      selectedBranch: null,
      modalType: null,
      orderid: '',
      currentStatus: '',
      selectedRow: [],
      isLoading: false,
      isEditable: true,
      refundType: null,
      checkbox: false,
      modalVisible: false,
      modalBulkDate: false,
      selectedDate: new Date().toISOString().split('T')[0],
      b2b_columns: [
        {
          label: 'Order ID',
          field: 'reference_id',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Quantity Box',
          field: 'quantity',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          sortable: false,
        },
        {
          label: 'PO Expiry Date',
          field: 'po_expiry_date',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: 'Order Date',
          field: 'date_added',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          label: "Sender's Name",
          field: 'sender.name',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Sender's Address",
          field: 'sender_address',
          thClass: 'text-center align-middle text-nowrap',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Recipient's Name",
          field: 'recipient.name',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Recipient's Address",
          field: 'recipient_address',
          thClass: 'text-center align-middle text-nowrap',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Recipient's Contact",
          field: 'recipient.phone',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle text-center text-nowrap',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
      ],
      minidc_columns: [
        {
          label: 'Order ID',
          field: 'reference_id',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'MiniDC Order ID',
          field: 'minidc_id',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
          sortable: false,
        },
        {
          label: 'MiniDC Outlet',
          field: 'minidc_branch',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          label: 'Order Date',
          field: 'date_added',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Sender's Name",
          field: 'sender.name',
          thClass: 'text-center align-middle text-nowrap',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Sender's Address",
          field: 'sender_address',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Recipient's Name",
          field: 'recipient.name',
          thClass: 'text-center align-middle text-nowrap',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: "Recipient's Address",
          field: 'recipient_address',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle',
          tdClass: 'align-middle',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    modalTitle() {
      if (this.modalType === 'accept_declined') {
        return 'Payment Details'
      }

      if (this.modalType === 'mark_as_paid') {
        return `Set Order #B${this.selectedRow.id} Active`
      }

      return this.modalType ? this.modalType.replace('-', ' ').toUpperCase() : ''
    },
    modalSize() {
      const modals = ['update-status', 'edit-order', 'accept_declined', 'mark_as_paid']

      if (this.modalType === 'request-refund' || this.modalType === 'refund-details') {
        return 'lg'
      }

      return modals.includes(this.modalType) ? 'md' : 'xl'
    },
  },
  created() {
    this.fetchOrders('');
  },
  methods: {
    async fetchOrders(type) {
      if (type === 'minidc_orders') {
        this.columns = this.minidc_columns
      } else {
        this.columns = this.b2b_columns
      }
      if (type === 'active') {
        this.checkbox = true
      } else {
        this.checkbox = false
      }
      this.resetRow()
      this.isLoading = true
      const query = `type=${type}`
      const response = await axios.get(`order-tracker?${query}`)
      this.rows = response.data.orders
      this.branches = response.data.branches.data
      this.currentStatus = type
      this.isLoading = false
    },

    async callUpdate() {
      if (!this.selectedBranch) {
        this.$toast.error('Please select a branch.')
        return
      }
      const response = await this.$http.post('order-tracker', {
        orderid: this.orderid,
        branchid: this.selectedBranch.id,
        branchname: this.selectedBranch.address,
      })
      if (response.status === 200) {
        this.$swal({
          title: 'Update success!',
          icon: 'success',
          text: `Transfered to ${this.selectedBranch.address} outlet successfully.`,
          customClass: {
            confirmButton: 'btn-primary',
          },
        })
        this.modalVisible = false
        this.fetchOrders(this.currentStatus)
      }
    },

    async callUpdateDate() {
      const response = await this.$http.post('order/update_delivery_date', {
        orderid: this.orderid,
        selectedDate: this.selectedDate,
      })
      if (response.status === 200) {
        this.$swal({
          title: 'Update success!',
          icon: 'success',
          text: `Updated delivery date successfully.`,
          customClass: {
            confirmButton: 'btn-primary',
          },
        })
        this.modalBulkDate = false
        this.fetchOrders(this.currentStatus)
      }
    },

    async handleAction(type, row, status = null) {
      const refundStatus = ['in-process', 'paid', 'decline-request', 'approved']
      if (refundStatus.includes(type)) {
        await axios.patch(`/update-refund-status/${row.refund.id}?status=${status}`)
        this.$swal({
          title: 'Update success!',
          icon: 'success',
          text: `You have updated the refund status of ${row.reference_id}`,
          customClass: {
            confirmButton: 'btn-primary',
          },
        })
        this.fetchOrders(this.currentStatus)
        return
      }

      this.modalType = type
      this.$refs.modal.show()
      this.selectedRow = row
    },

    async transferMiniDC(value) {
      this.modalVisible = true
      this.orderid = value
    },

    async bulkEditDeliveryDate(value) {
      this.modalBulkDate = true
      this.orderid = value
    },

    async handleModalClosure() {
      await this.fetchOrders(this.currentStatus)
      this.$refs.modal.hide()
    },
    async resetRow() {
      this.rows = []
    },
    handleReportExport(type) {
      const other = this.rows.map((item) => ({
        // ...item,
        id: item.id,
        quantity: item.quantity,
        po_expiry_date: item.po_expiry_date,
        order_date: item.date_added,
        delivery_date: item.delivery_date,
        status: item.status.text,
        sender_name: item.sender.name,
        sender_contact: item.sender.phone,
        sender_address: item.sender.complete,
        recipient_name: item.recipient.name,
        recipient_contact: item.recipient.phone,
        recipient_address: item.recipient.complete,
      }))
      if (type == 'xlsx') {
        const ws = XLSX.utils.json_to_sheet(other)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'sheet')
        XLSX.writeFile(wb, `b2b_export${Date.now()}.xlsx`)
      } else if (type == 'csv') {
        const blob = new Blob([Papa.unparse(other)], {
          type: 'application/vnd.ms-excel;charset=utf-8;',
        })
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', `b2b_export${Date.now()}.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
